.slicknav_btn {
	display: block;
	vertical-align: middle;
	float: left;
	padding: 24px 16px 10px 10px;
	line-height: 1.125em;
	cursor: pointer;
	/*position: absolute;
	top: -0.24em;
	right: 0;*/
	text-decoration: none;
	font-size: 38px;

	.slicknav_icon-bar + .slicknav_icon-bar {
		margin-top: 0.26em;
	}
	@media only screen and (max-width: 479px) {
		font-size: 35px;
	}

	&.slicknav_open {
		z-index: 3;
		position: fixed;
		margin: 0px;
		padding: 0px;
		width: 100%;
		left: 0;
		height: 60px;
		top: 0;
		background-color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid #e7e7e7;

		.slicknav_icon span {
			&:first-of-type {
				transform: rotate(45deg);
				top: 11px;
			}

			&:nth-of-type(2) {
				display: none;
			}

			&:last-of-type {
				transform: rotate(315deg);
			}
		}
	}
}

.slicknav_menu {
	flex: 1 1 49%;
	*zoom: 1;
	font-size: 16px;
	box-sizing: border-box;
	* {
		box-sizing: border-box;
	}
	.slicknav_icon {
		float: left;
        width: 0.9em;
        height: 0.875em;
		margin: 0.188em 0 0 0.438em;
        &:before {
            background: transparent;
            width: 0.9em;
            height: 0.875em;
            display: block;
            content: "";
            position: absolute;
        }
	}
	.slicknav_no-text {
		margin: 0;
	}
	.slicknav_icon-bar {
		position: relative;
		display: block;
		width: 0.9em;
		height: 2px;
		border-radius: 3px;
		box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
		background-color: #222;

		transition-delay: 0.01s;
		transition: all 0.04s ease-out;
	}

	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}

	@media only screen and (min-width: 900px) {
		display: none;
	}
}

.slicknav_nav {
	clear: both;
	color: map-get($colours, blue);
	margin: 0;
	padding: 0;
	font-size: 0.875em;
	list-style: none;
	overflow: hidden;
	text-align: center;

	ul {
		display: block;
		list-style: none;
		overflow: hidden;
		padding: 0;
		margin: 0;
		border-bottom: 1px solid #dae4b2;
	}
	li {
		display: block;
	}
	> li:last-of-type {
		margin-bottom: 20px;
	}
	.slicknav_arrow {
		font-size: 0.8em;
		margin: 0 0 0 0.4em;
	}
	.slicknav_item {
		cursor: pointer;
		a {
			display: inline;
			padding: 0;
			margin: 0;
		}
	}
	.slicknav_row {
		display: block;
		padding: 18px 10px 14px;
		margin: 0px 5px;

		&:hover {
			background: map-get($colours, light-grey);
		}
	}
	nav {
		& > div, a {
			display: block;
			padding: 19px 0px 20px;
			margin: 0px;
			text-decoration: none;
			color: map-get($colours, green);
			font-size: 20px;
			outline: none;
			border-bottom: 1px solid #e7e7e7;
		}

		> a:last-of-type {
			display: none;
		}

		& > div {
			cursor: pointer;

			& > div:first-of-type {
				position: relative;
				display: inline-block;
				padding: 0px;
				margin-left: -22px;

				&::before {
					content: "";
					position: absolute;
					width: 16px;
					height: 16px;
					top: 2px;
					right: -22px;
					background-repeat: no-repeat;
					background-position: center;
					background-size: 16px;
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 22.523739 13.763274'%3E%3Cpath stroke-linejoin='round' d='m1.25 1.25 10 11.25 10-11.25' stroke='rgb(57,63,72)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2.5' fill='none'/%3E%3C/svg%3E");
					transition: transform 0.14s ease-out;
					transform: rotate(270deg);
				}

				img {
					display: none;
				}
			}

			& > div:last-of-type {
				opacity: 0;
				max-height: 0px;
				overflow-y: hidden;
				transition: all 0.57s ease-out, opacity 0.3s ease-out;
				padding-bottom: 0px;
				padding-left: 0px;
				padding-right: 0px;

				a {
					color: #929292;
					outline: none;

					&:first-of-type {
						margin-top: 12px;
						border-top: 2px solid #e0e0e0;
					}

					&:last-of-type {
						padding-bottom: 0px;
					}
				}

				&.scroll {
					overflow-y: auto;
				}
			}

			&.open {
				background-color: #ededed;

				& > div:first-of-type {
					&::before {
						transform: rotate(359deg);
					}
				}

				& > div:last-of-type {
					opacity: 1;
					max-height: 400px;
				}
			}
		}
	}
	.slicknav_parent-link a {
		display: inline;
		padding: 0;
		margin: 0;
	}
	.slicknav_txtnode {
		margin-left: 15px;
	}

	@media only screen and (max-width: 900px) {
		position: fixed;
		height: calc(100% - 60px);
		width: 100%;
		background-color: white;
		overflow: auto;
		left: 0;
		z-index: 2;
		top: 60px;
	}
}
