/*
Utilities are helper classes that can override the values specified elsewhere. This means !important is allowed and
actually encouraged, see http://csswizardry.com/2016/05/the-importance-of-important/
*/


//   ##   #     ###    ###  #  #  #  #  ####  #  # #####        #  #         ###  ###    ##    ###  ###   #  #   ###
//  #  #  #      #    #     ## #  ####  #     ## #   #           ##         #     #  #  #  #  #      #    ## #  #
//  #  #  #      #    # ##  # ##  ####  ###   # ##   #          ####         ##   ###   #  #  #      #    # ##  # ##
//  ####  #      #    #  #  #  #  #  #  #     #  #   #           ##            #  #     ####  #      #    #  #  #  #
//  #  #  ####  ###    ###  #  #  #  #  ####  #  #   #          #  #        ###   #     #  #   ###  ###   #  #   ###
//

/*
These classes are used to space items vertically and consistently. Use them to to space out core blocks of content.
*/

.u-m {

	&b-auto {
		margin-bottom: auto;
	}

	@each $space in $spaces {
		&b-#{$space} {
			margin-bottom: #{$space}px !important;
		}
	}

	&t-auto {
		margin-top: auto;
	}

	@each $space in $spaces {
		&t-#{$space} {
			margin-top: #{$space}px !important;
		}
	}
}

.u-p {
	@each $space in $spaces {
		&b-#{$space} {
			padding-bottom: #{$space}px !important;
		}
	}

	@each $space in $spaces {
		&t-#{$space} {
			padding-top: #{$space}px !important;
		}
	}
}

.b-p {
	&-5 {
		padding-bottom: 5px;
	}

	&-10 {
		padding-bottom: 10px;
	}

	&-20 {
		padding-bottom: 20px;
	}

	&-30 {
		padding-bottom: 30px;
	}

	&-40 {
		padding-bottom: 40px;
	}

	&-50 {
		padding-bottom: 50px;
	}

	&-60 {
		padding-bottom: 60px;
	}

	&-70 {
		padding-bottom: 70px;
	}

	@media only screen and (max-width:500px) {
		&-50, &-60, &-70 {
			padding-bottom: 10px;
		}
	}
}

/*
Spans are used to fix the width of elements on the website. We give spans their maximum width - that used for desktop,
then a percentage width. By setting this less then 100% smaller screens will keep a little space on each side that
scales are the device does.
*/
.u-w {
	width: 100%;

	/*
	Centre an element on the page
	*/
	&--centred {
		margin-left: auto !important;
		margin-right: auto !important;
	}

	@each $key, $width in $widths {
		&-#{$key} {
			width: 96%;
			max-width: #{$width} !important;
		}
	}
}
