.pagination {
	position: relative;
	justify-content: center;
	min-height: 45px;

	align-items: center;
	display: flex;
	margin-bottom: 30px;
	font-weight: $font-bold-weight;

	a {

	}

	&__numerical {
		a {
			font-weight: 400;
			font-size: 14px;
			padding: 7px 4px 4px 4px;
			background-color: #f2a270;
			margin: 0 1px 0 1px;
			color: map_get($colours, pure-white);
		}

		a.active {
			background-color: map_get($colours, light-orange);
		}
	}

	&__item {
		padding: 0 10px;

		&--disabled {
			opacity: .5;
		}

		&:hover, &:active, &--active {
			color: map_get($colours, body-text);
		}

		&--first {
			&::after {
				content: '...';
			}
		}

		&--last {
			&::before {
				content: '...';
			}
		}
	}

	&__pos {
		position: relative;
		display: inline-block;
		margin-top: 0px;
		margin-bottom: 0px;
		background-color: #FFF;
		border-radius: 40px;
		max-height: 72px;
		background-color: transparent;
		top: 0px;

		&--left {
			left: -18px;
		}

		&--right {
			right: -20px;
		}

		&::after {
			content: "";
			position: absolute;
			top: 0px;
			right: 0px;
			height: 44px;
			width: 44px;
			background-color: #49505a;
			border-radius: 40px;
			cursor: pointer;
			z-index: 1;
			background-repeat: no-repeat;
			background-position: 21px center;
			background-size: 14px;
		}
	}

	&__bg {
		position: relative;
		color: #49505a;
		background-color: transparent;
		padding: 16px 27px 26px 14px;
		border: 1px solid transparent;
		border-radius: 40px;
		font-size: 20px;
		line-height: 1;
		font-family: "FSAlbert", Arial, sans-serif;
		cursor: pointer;
		z-index: 3;
		outline: 0 !important;
		user-select: none;
	}

	&__dir {
		position: absolute;
		top: 13px;
		right: 8px;
		width: 22px;
		height: 18px;
		z-index: 2;
		stroke: #fff;
		cursor: pointer;
		transition: right 0.07s ease-out;

		&--left {
			transform: rotate(180deg);
			right: 11px;
		}
	}
}

.featured-jobs .pagination__pos--right {
	right: -20px;
}