

.footer {
	padding-top: 65px;
	padding-bottom: 65px;

	&__contact {
		& p:first-of-type {
			padding-top: 0px;
			padding-bottom: 1px;
		}
	}

	&__nav {
		columns: 2 150px;
		column-gap: 0;

		a {
			display: block;
			margin-bottom: 1.2em;

			span {
				text-decoration: underline;
				padding-right: 10px;
			}
		}

		@media only screen and (max-width: 700px) {
			columns: 1;
			text-align: center;
			margin: 12px;
		}
	}


	&__copyright-text {
		width: 100%;
		flex: 1 1 100%;
		color: #c3c3c3;
		font-size: 12px;
		padding: 25px 13px;
	}


	&__offsite {
		text-align: right;

		a {
			display: inline-block;
			margin-left: 30px;

			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			   margin-left: 10px;
			}

			img {
				width: 100%;
				height: 100%;
				max-height: 70px;

				&.sml {
					padding-top: 5px;
					padding-bottom: 5px;
				}
			}

			&:first-of-type {
				margin-left: 0px;
			}

			@media only screen and (max-width: 700px) {
				width: 15%;
			}
		}

		@media only screen and (max-width: 700px) {
			text-align: center;
		}
	}

	p {
		padding-top: 5px;
		padding-bottom: 13px;
	}

	@media only screen and (max-width: 700px) {
		padding-top: 20px;
		padding-bottom: 27px;
	}
}