

.button {
	align-self: flex-start;
	display: inline-block;
	padding: 11px 40px 5px 40px;
	user-select: none;

	background-color: transparent;
	border: none;

	color: inherit;
	font-family: inherit;
	font-weight: $font-bold-weight;
	line-height: 1;

	cursor: pointer;

	transition: all .2s linear;

	&:link, &:visited {
		color: inherit;
	}

	&--orange {
		position: relative;
		flex: 1 1 auto;
		padding: 14px 50px 12px 50px;
		margin: 0 auto;
		background: #F46720;
		background: linear-gradient(to right, #f6822b 0%, #f46720 100%);
		color: #FFF;
		outline: none;
		cursor: pointer;
		font-weight: normal;
		text-align: center;

		&::after {
			content: "";
			position: absolute;
			height: 2px;
			width: 0px;
			background-color: map_get($colours, pure-white);
			bottom: 12px;
			left: 50%;
			opacity: 0;
			transition: all 0.3s ease-out;
		}

		&:hover, &:active {
			padding-top: 11px;
			/*padding-bottom: 15px;*/

			&::after {
				opacity: 1;
				width: 88px;
				left: calc(50% - 44px);
			}
		}
	}

	&--clear {
		position: relative;
		flex: 1 1 auto;
		padding: 14px 50px 12px 50px;
		margin: 0 auto;
		outline: none;
		cursor: pointer;
		font-weight: normal;
		text-align: right;
	}

	&--dd {
		position: relative;
		padding: 11px 46px 9px 10px;

		&::after {
			content: none;
		}

		& .arrow {
			position: absolute;
			right: 10px;
			top: 9px;
			height: 16px;
			transition: all 0.05s ease-out;
			transform: rotate(0deg);
		}

		& .dropdown {
			position: absolute;
			top: 100%;
			left: -2px;
			right: 0;
			background: #FFF;
			list-style: none;
			font-weight: normal;
			text-transform: none;
			text-align: left;
			opacity: 0;
			pointer-events: none;
			transition: all 0.1s ease-out;
			min-width: calc(100% + 4px);
			box-shadow: -6px 6px 10px 0px rgba(0, 0, 0, 0.02), 6px 6px 10px 0px rgba(0, 0, 0, 0.02);

			&.scroll {
				overflow-x: hidden;
				overflow-y: scroll;
			}
		}

		& .dropdown li a {
			display: block;
			text-decoration: none;
			color: #939393;
			padding: 12px 20px 10px 20px;
		}

		& .dropdown li:hover a {
			background: #f3f3f3;
		}

		&.active .dropdown {
			opacity: 1;
			pointer-events: auto;
		}

		&:focus, &.focused {
			.arrow {
				transform: rotate(90deg);
			}
		}

		&:focus .dropdown, &.focused .dropdown {
			opacity: 1;
			pointer-events: auto;
			outline: none;

			@media only screen and (max-width: 700px) {
				max-height: 270px;

				@media only screen and (max-width: 700px) {
					max-height: 195px !important;
					overflow-y: scroll;
				}
			}
		}
	}

	&--chevron {
		&::after {
			content: "";
			position: absolute;
			width: 16px;
			height: 16px;
			top: 10px;
			right: 10px;
			background-repeat: no-repeat;
			background-position:center;
			background-size: 16px;

			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 22.523739 13.763274'%3E%3Cpath stroke-linejoin='round' d='m1.25 1.25 10 11.25 10-11.25' stroke='rgb(57,63,72)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2.5' fill='none'/%3E%3C/svg%3E");
		}

		&:focus {
			&::after {
				top: 11px;
				transform: rotate(180deg);
				animation: rotatechevron 0.02s ease-in-out;
			}
		}
	}

	&--action, &--quick {
		position: relative;
		background-color: map_get($colours, dark-grey);
		color: map_get($colours, pure-white);
		width: 100%;
		min-height: 40px;
		padding-top: 7px;
		border: 2px solid map_get($colours, pure-white);

		span {
			display: block;
			padding-top: 4px;
		}

		&:hover, &:active {
			padding-top: 3px;
		}
	}

	&--quick {
		/*background-color: map_get($colours, pure-white);
		color: #49505a;*/
		background-color: transparent;
	}

	&--proceed {
		position: relative;
		background-color: map_get($colours, orange);
		color: map_get($colours, pure-white);
		border: 2px solid transparent;
		min-height: 40px;
		padding-top: 7px;
		padding-left: 0px;
		padding-right: 0px;
		transition: all 0.2s ease-out;

		span {
			display: block;
			padding-top: 4px;
			transition: color 0.2s ease-out;
		}

		&:hover, &:active {
			background-color: map_get($colours, pure-white);
			color: map_get($colours, orange);
			border: 2px solid map_get($colours, orange);

			span {
				color: map_get($colours, orange);
			}
		}
	}

	&--search-select {
		position: relative;
		background-color: map_get($colours, mid-grey);
		color: map_get($colours, dark-grey);
		width: 100%;
		min-height: 40px;
		min-width: 160px;
		padding: 11px 40px 5px 14px;
		outline: none;
		transition: all 0.07s ease-out;
		font-weight: 400;
		box-sizing: border-box;
		border: 2px solid map_get($colours, pure-white);

		&:before {
			content: none;
		}

		&:focus {
			span {
				/*color: white;*/
			}
		}
	}

	&--category {
		& .dropdown {
			min-width: 300px !important;
		}
	}

	&--inline {
		@media only screen and (max-width: 700px) {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
}

.more-link {
	padding-bottom: .5em;

	border-bottom: solid 2px currentColor;

	color: map_get($colours, green);
	font-weight: $font-bold-weight;

	&:link, &:visited {
		color: map_get($colours, green);
	}

	&:hover, &:active {
		color: map_get($colours, blue);
	}
}
