.header {
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;
	padding: 0;
	position: fixed;
	width: 100%;
	z-index: 100;
	min-height: 95px;

	background-color: map_get($colours, pure-white);
	text-align: center;
	box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.07);

	&__logo {
		height: 44px;
	}
}

@keyframes chevron {
	0%	{
		opacity: 1;
		transform: rotate(0deg);
	}
	40%	{
		opacity: 0.4;
		transform: rotate(270deg);
	}
	80% {
		opacity: 1;
		transform: rotate(160deg);
	}
	100% {
		opacity: 1;
		transform: rotate(180deg);
	}
}

.nav {
	align-items: flex-end;
	display: flex;
	justify-content: space-between;
	margin-left: auto;
	margin-right: 0px;
	font-size: 16px;

	&__items {
		display: flex;
		height: 100%;

		& .nav__item:nth-last-child(2) {
			padding-right: 15px;
		}
	}

	&__item {
		display: flex;
		position: relative;
		padding: 0px 0px 0px 0px;
		margin: 0;
		cursor: pointer;
		flex: 1 1 auto;
		outline: none;
		transition: all 0.15s ease-out;

		&:hover, &:active, &--active {
			background-color: map_get($colours, bg-grey);
			transition: all 0.07s ease-out;
		}

		&--has-sub {
			.nav__label {
				padding-right: 38px;
				pointer-events: none !important;

				&::after {
					content: "";
					position: absolute;
					width: 16px;
					height: 16px;
					top: 45%;
					top: calc(50% - 8px);
					right: 15px;
					background-repeat: no-repeat;
					background-position:center;
					background-size: 16px 16px;
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 22.523739 13.763274'%3E%3Cpath stroke-linejoin='round' d='m1.25 1.25 10 11.25 10-11.25' stroke='rgb(57,63,72)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2.5' fill='none'/%3E%3C/svg%3E");
				}
			}

			&:focus {
				background-color: map_get($colours, bg-grey);

				.nav__label {
					&::after {
						transform: rotate(180deg);
						animation: chevron 0.02s ease-in-out;
					}
				}

				.sub-nav {
					opacity: 1;
					max-height: 500px;
					transition: max-height 0.4s ease-out;
				}
			}
		}

		& .sub-nav-container {
			position: absolute;
			bottom: 0px;
			left: 0px;
			height: 0px;
			min-width: 100%;
			overflow: visible;
		}

		& .sub-nav {
			display: block;
			opacity: 0;
			z-index: 5;
			padding-top: 0px;
			padding-bottom: 0px;
			padding-left: 28px;
			padding-right: 28px;
			margin-left: 0;
			margin-top: 0px;
			min-width: 100%;
			max-height: 0px;
			overflow: hidden;
			outline: none;
			text-align: left;
			border: 0px solid transparent;
			background-color: map_get($colours, bg-grey);
			transition: opacity 0.04s ease-out, max-height 0s ease-out;
			transition-delay: 0s, 0.05s;
			box-shadow: -8px 8px 16px 0px rgba(0, 0, 0, 0.02), 8px 8px 16px 0px rgba(0, 0, 0, 0.02);

			&:hover {
				opacity: 1;
				max-height: 500px;
			}

			&__item {
				display: block;
				padding: 13px 0 13px 0;
				width: 100%;
				margin: 0px auto;
				transition: color 0.07s ease-out;
				position: relative;
				white-space: nowrap;

				&:first-of-type {
					margin-top: 28px;
				}

				&:last-of-type {
					margin-bottom: 26px;
				}

				&:hover {
					color: #7e7e7e;
				}
			}
		}
	}

	&__label {
		position: relative;
		display: flex;
		padding: 0px 15px 0px 15px;
		transition: all 0.1s ease-out;
		align-self: center;

		span {
			align-self: center;
			display: inline-block;
		}
	}

	&__account {
		background-color: map_get($colours, dark-grey) !important;
		/* background-color: map_get($colours, orange) !important; NEW DESIGN */
		color: map_get($colours, pure-white);

		.nav__label {
			padding-left: 32px;
			padding-right: 32px;
			text-align: left;
			color: map_get($colours, pure-white);

			img {
				position: relative;
				top: -1px;
				margin-right: 10px;
				width: 26px;
				height: 38px;
			}

			@media only screen and (max-width: 900px) {
				margin-left: auto;
				margin-right: auto;
			}
		}

		& .sub-nav {
			background-color: map_get($colours, dark-grey);
			left: initial;
			right: 0px;

			& a:hover {
				color: #b7b7b7;
			}
		}
	}

	&__contact {
		position: relative;
		flex: 0 1 auto;
		padding: 15px 0px 11px 10px;
		color: map_get($colours, orange);

		&::before {
			content: "";
			position: absolute;
			top: 50%;
			left: 0%;
			height: 0%;
			width: 2px;
			z-index: 2;
			background-color: #ebebeb;
			transition: all 0.1s ease-out;
		}
	}

	@media only screen and (max-width: 900px) {
		display: none;
	}
}

.sub-nav {
	display: none;
	left: 0;
	padding: 30px 0;
	position: absolute;

	&__item {
		margin: 0 20px;
	}
}


/*
This pushes the content down a bit so it’s not under the fixed header
*/

.fixed-header-pusher {
	height: 95px;

	@media only screen and (max-width: 900px) {
		height: 148px;
	}
}
