

.control {
	display: block;

	margin-bottom: 30px;
	padding-bottom: 14px;
	width: 650px;

	border-bottom: solid 1px map_get($colours,  body-text);

	&__label {
		align-items: baseline;
		display: flex;

		font-size: 16pt;
	}

	&__name {
		width: 170px;

		color: map_get($colours, body-text);
		font-weight: $font-body-weight;

		cursor: pointer;
	}

	&__control {
		flex-grow: 1;

		padding: 7px 9px;

		border-color: map_get($colours, body-text);
		border-radius: 2px;

		color: #555;
		font-family: system, sans-serif;

		transition: border-color .3s linear;

		&:hover, &:focus {
			border-color: map_get($colours, body-text);
		}
	}

	&__error {
		margin-bottom: 5px;
		padding: 8px;

		background-color: map_get($colours, body-text);
		border-radius: 2px;

		color: #fff;
		font-size: .8em;
		line-height: 1.2;
	}

	&__help {
		margin-top: 5px;

		font-size: .8em;
	}
}

.field-large {
	input, textarea, select, .clone {
		border: none;
		min-height: 40px;
		margin-top: 6px;
		margin-bottom: 22px;
	}

	input {
		padding-left: 14px;
	}

	.textarea {
		max-height: 200px;
	}

	textarea, .clone {
		padding: 11px 14px;
	}

	@media only screen and (max-width: 610px) {
		flex: 1 1 100%;
	}
}

.checkboxes,
.radiobuttons {
	display: flex;
	flex-wrap: wrap;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 500px;

	&--large {
		max-width: 840px;
	}
}

.checkboxes input[type=checkbox],
.radiobuttons input[type=radio] {
	display: none;
}

.checkboxes input[type=checkbox] + label,
.radiobuttons input[type=radio] + label {
	flex: 1 1 auto;
	border: 2px solid rgba(255, 255, 255, 0.3);
	margin: 10px;
	padding: 11px 15px 9px 15px;
	font-size: 20px;
	cursor: pointer;

	&.btFull {
		flex: 1 1 100%;
	}

	&.btHalf {
		flex: 1 1 30%;
	}

	&.btQuart {
		flex: 1 1 20%;
		max-width: 190px;
	}

	&.btCenter {
		margin-top: 30px;
		margin-left: auto;
		margin-right: auto;
	}

	&.btPadd {
		margin-top: 40px;
	}
}

.checkboxes input[type=checkbox]:checked + label,
.radiobuttons input[type=radio]:checked + label {
	border: 2px solid rgba(255, 255, 255, 1);
}

.extraPadd {
	padding-bottom: 90px !important;
}
