/*

		   @@@
		 @ .. @
		 @\=/@
		 .- -.
		/(.|.)\
		\ ).( /
		'( v )`
		  \|/
		  (|)
		  '-`

 ###    ##   ###   ####
 #  #  #  #  #  #  #
 ###   #  #  ###   ###
 # #   ####  # #   #
 #  #  #  #  #  #  ####

 A CSS FRAMEWORK FOR
 BEAUTIFUL WEBSITES

*/

// Basic settings and style setup
@import "settings";

@import "vendor/normalize";

@import "utils/debug";
@import "utils/rare-reset";

// Reusable classes for common layout requirements that
// aren’t specific to any one block or component
@import "utils/grid";
@import "utils/spacing";
@import "utils/colours";
@import "utils/misc";

// Shared components or blocks such as buttons, inputs and BEM blocks
@import "components/typography";
@import "components/buttons";
@import "components/controls";
@import "components/pagination";
@import "components/table";
@import "components/media-block";

// Layouts used for the site broken into various
// sections for partials, pages and general layout
@import "layouts/general";
@import "layouts/partials/header";
@import "layouts/partials/footer";
@import "layouts/partials/hero";

@import "layouts/pages/home";
@import "layouts/pages/consultants";
@import "layouts/pages/contact";


// All html from the CMS should be wrapped in a .cms-content class to keep
// it isolated, any styling required is added to the files in the /cms
// folder to keep it separate from the main website layout code
@import "cms/cms";

// This is where any nasty css lives
@import "shame";

/* Third party */
@import "vendor/slicknav";
@import "vendor/jquery-ui";
@import "vendor/jquery-ui.theme";
@import "vendor/owl.carousel";
@import "vendor/owl.theme.default";