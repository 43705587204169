.square-grid {
	display: flex;
	flex-flow: row wrap;
	flex-wrap: wrap;
	justify-content: flex-start;
	width: 100%;
	width: calc(100% + 32px);
	margin-left: -4px;

	color: map_get($colours, white);

	.square {
		position: relative;
		flex: 0 1 25%;
		flex-wrap: wrap;
		align-self: flex-start;
		max-width: 25%;
		padding: 16px;
		cursor: default;
		outline: none;

		&--about {
			display: flex;
			flex-direction: column;
			align-self: auto;
			cursor: default;
			padding-top: 0px;
		}

		.sq_img {
			width: 100%;
			height: auto;
			cursor: pointer;
		}

		&__inner {
			position: relative;
			width: 100%;
			padding-bottom: 0px;

			& .square-grid__bg, & .square-grid__content {
				width: 100%;
				bottom: 0px;
				cursor: default;
			}

			& .sq_img {
				cursor: default;
			}
		}

		&__biography {
			padding: 5px 20px 20px;
			background-color: map_get($colours, light-grey);
			color: #000;
			font-weight: 300;
			line-height: 22px;
			flex: 2;
			text-align: left;
		}
	}

	&.desaturate a .sq_img {
		filter: grayscale(100%);
		transition: all .2s ease-out;
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: center;

		flex: 0 1 33.33%;

		max-width: calc(33.33% - 10px);
		background-color: map_get($colours, orange);

		margin: 5px;
		min-height: 80px;
	}

	a:hover .sq_img {
		filter: grayscale(0%);
	}

	&__bg, &__content {
		position: absolute;
		bottom: 5px;
		width: 100%;
		width: calc(100% - 20px);
		height: 56px;
	}

	&__bg {
		background-color: map_get($colours, dark-grey);
		width: calc(100% - 32px);
	}

	&__content {
		padding: 7px 10px 0 0;
		cursor: pointer;
		color: map_get($colours, pure-white);
	}

	&__name {
		font-size: 15pt;
		text-align: center;
	}

	@media only screen and (max-width: 768px) {
		padding: 5px;
	}

	@media only screen and (max-width: 768px) {
		&__header, .square {
			flex: 0 1 50%;
		}
		&__header {
			max-width: calc(50% - 10px);
		}
	}
	@media only screen and (max-width: 479px) {
		&__header, .square {
			flex: 0 1 100%;
		}
		&__header {
			max-width: calc(100% - 10px);
		}
	}
}

.bio {
	&__text {
		width: 70%;
		flex: 1 0 100px;
		margin: 16px;
		margin-bottom: 6px;
		padding: 1em;
		background-color: map_get($colours, pure-white);
		text-align: left;
	}
	&.square-grid .square img, div {
		cursor: default;
	}
}

.square-cell {
  display: flex;
  padding: 0 5px;

  &__header {
	width: 30%;
	max-width: 300px;
	margin-right: 50px;
  }

  &__image {
	margin-bottom: 25px;
	width: 100%;
	max-width: 300px;
  }
}
