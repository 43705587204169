.logo-tp {
	margin-top: 28px;
	margin-bottom: 23px;
	margin-left: 35px;
	margin-right: auto;
	text-align: left;

	&__text {
		line-height: 44px;

		@media only screen and (min-width: 900px) and (max-width: 1170px) {
			display: none;
		}
	}

	.contact-logo {
		height: 36px;
	}

	@media only screen and (max-width: 900px) {
		width: 100%;
		margin-top: 20px;
		margin-bottom: 0px;
		margin-left: 0px;
		margin-right: 0px;
		text-align: center;

		> a {
			display: inline-block;
			margin-bottom: 10px;
			padding-left: 2%;
			padding-right: 3%;
		}

		&__text {
			font-size: 32px;
		}
	}

	@media only screen and (max-width: 500px) {
		&__text {
			font-size: 8.5vw;
		}
	}
}

.mob_nav {
	display: none;
	width: 100%;

	&__half {
		width: 50%;
		min-height: 75px;
	}

	&__account {
		display: flex;
		margin: 0;
		padding: 20px 0;
		width: 100%;
		height: 100%;
	}

	&__label {
		font-size: 14pt;
		flex: 1 1 51%;
		text-align: right;
		align-self: center;
	}

	&__menu {
		display: flex;
		align-content: center;
		cursor: pointer;
	}

	@media only screen and (max-width: 900px) {
		display: flex;
	}
}

.hero-large {
	position: relative;
	display: flex;
	z-index: 5;
	min-height: 420px;
	height: 70vh;
	height: calc(70vh - 95px);
	overflow: visible;

	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
	background-size: cover;
	background-image: url("../../img/hero-home.png");

	@media only screen and (max-width: 900px) {
		height: 50vw;
		min-height: auto;

		.searchbox--hero-bar {
			display: none;
		}
	}
}

@keyframes rotatechevron {
	0%	{
		opacity: 1;
		transform: rotate(0deg);
	}
	40%	{
		opacity: 0.4;
		transform: rotate(270deg);
	}
	80% {
		opacity: 1;
		transform: rotate(160deg);
	}
	100% {
		opacity: 1;
		transform: rotate(180deg);
	}
}

.searchbox {
	display: flex;
	width: 100%;
	z-index: 6;

	&__posfix {
		width: 100%;
		margin-left: auto;
		margin-right: auto;

		@media only screen and (max-width:1220px) and (min-width:900px) {
			margin: 0 20px 0 20px;
		}
	}

	&__content {
		display: flex;
		max-width: 100%;
		flex-wrap: wrap;
		align-items: flex-end;
		width: 100%;
		padding: 18px;
		padding-bottom: 20px;

		div:first-of-type {
			margin-left: 0px;
		}

		div:last-of-type {
			margin-right: 0px;
		}

		@media only screen and (max-width: 900px) {
			background-color: #f36518;
		}

		@media only screen and (max-width: 500px) {
			justify-content: center;
		}
	}

	&__title {
		flex: 0 1 auto;
		margin-bottom: 10px;
		font-size: 16pt;
		margin-right: 20px;

		@media only screen and (max-width: 900px) {
			display: block;
			width: 100%;
			text-align: center;
			margin-top: 20px;
			margin-bottom: 15px;

			img {
				width: 18px;
			}
		}
	}

	.submit-group {
		position: relative;
		flex: 0;

		&::after {
			content: "";
			position: absolute;
			height: 2px;
			width: 0px;
			background-color: map_get($colours, pure-white);
			bottom: 11px;
			left: 50%;
			opacity: 0;
			transition: all 0.3s ease-out;
		}

		&:hover, &:active {
			&::after {
				opacity: 1;
				width: 84px;
				left: calc(50% - 42px);
			}
		}
	}

	.form-group {
		label {
			display: inline-block;
			flex: 1 1 auto;
			min-height: 40px;
			color: #807c7e;
			background-color: map_get($colours, bg-grey);
			font-size: 15px;
			padding: 14px 0px 0px 0px;
			width: 100px;
			text-align: center;

			@media only screen and (max-width: 900px) {
				display: none;
			}
		}

		.form-control {
			flex: 1 1 auto;
			margin-top: 0px;
			font-size: 11pt;
			width: calc(100% - 100px);
			vertical-align: top;
			background-color: white;
			color: #807c7e;

			@media only screen and (min-width: 610px) {
				margin-bottom: 0px;
			}
		}

		&.keyword-group {
			@media only screen and (min-width: 610px) {
				::-webkit-input-placeholder {
					color: white;
				}

				::-moz-placeholder {
					color: white;
				}

				::-ms-placeholder {
					color: white;
				}

				::placeholder {
					color: white;
				}
			}
		}
	}

	&__select {
		font-size: 40px;
		line-height: 57px;
		text-align: center;
		color: map_get($colours, pure-white);

		& > div.button--dd {
			.dropdown {
				font-size: 12pt;
				background-color: map_get($colours, dark-grey);
				min-width: 120px;
				left: 0px;

				a {
					color: map_get($colours, pure-white);

					&:hover {
						background-color: map_get($colours, dark-grey);
						color: #b7b7b7;
					}
				}
			}

			&:first-of-type {
				z-index: 6;
			}

			&:last-of-type {
				z-index: 5;
			}
		}
	}

	&--hero {
		align-self: center;

		.searchbox__posfix {
			max-width: 600px;
		}

		.button--search-select {
			background-color: transparent;
			border: none;
			padding: 0;
			min-width: 0;
			display: inline-block;
			width: auto;

			.dropdown {
				overflow: auto;
			}

			> span {
				border-bottom: 2px dashed map_get($colours, dark-grey);
			}
		}

		.form-group {
			width: 100%;
			margin-bottom: 27px;
		}

		.submit-group-container {
			flex: 1 1 auto;
			width: 100%;
			display: block;
			text-align: center;
		}

		.submit-group {
			width: auto;
			margin: 0px auto 0px auto;
			display: inline-block;
		}

		.keyword-group {
			margin-left: 50px;
			margin-right: 50px;
		}
	}

	&--bar {
		position: relative;

		.searchbox__content {
			background-color: map_get($colours, orange);
		}

		.form-group {
			margin-right: 10px;
			margin-left: 10px;
			flex: 0 1 auto;

			@media only screen and (max-width: 610px) {
				margin: 0px;
			}
		}

		.submit-group {
			flex: 0 1 auto;
		}

		.keyword-group {
			flex: 1 1 auto;
		}

		.button--search-select {
			&::after {
				content: "";
				position: absolute;
				width: 16px;
				height: 16px;
				top: 10px;
				right: 10px;
				background-repeat: no-repeat;
				background-position:center;
				background-size: 16px;

				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 22.523739 13.763274'%3E%3Cpath stroke-linejoin='round' d='m1.25 1.25 10 11.25 10-11.25' stroke='rgb(57,63,72)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2.5' fill='none'/%3E%3C/svg%3E");
			}

			&:focus, &.focused {
				&::after {
					top: 11px;
					transform: rotate(180deg);
					animation: rotatechevron 0.02s ease-in-out;
				}
			}
		}

		@media only screen and (max-width: 900px) {
			.form-group {
				width: 90%;
				margin: 0 5% 12px 5% !important;
			}

			.form-control {
				width: 100% !important;
				margin-bottom: 0px;
			}

			div {
				&:nth-of-type(1) {
					z-index: 5;
				}

				&:nth-of-type(2) {
					z-index: 4;
				}
			}
		}
	}

	&--title-bar {
		align-self: center;
	}

	&__tagline {
		width: 100%;
		color: white;
		font-size: 70pt;
		text-align: center;
		margin-top: -20pt;

		span {
			padding-right: 6pt;
			font-family: CrimsonText-Italic;
		}

		@media only screen and (max-width: 900px) {
			margin-top: 0pt;
			font-size: 9vw;
		}
	}

	&--hero-bar {
		position: absolute;
		bottom: 0px;
		left: 0px;
		right: 0px;
		bottom: 4.5vh;

		.searchbox__posfix {
			max-width: 1030px;
		}

		label {
			padding: 14px 20px 0px 20px !important;
			width: auto !important;
			flex: 0 !important;
		}

		.keyword-group {
			display: flex;
		}

		.keyword-group input {
			width: auto !important;
		}

		.submit-group input {
			border: 0;
		}

		.form-group {
			margin-right: 7px;
			margin-left: 7px;
		}

		.searchbox__content {
			background-color: transparent;
			flex-wrap: nowrap;
			align-items: flex-start;
		}

		.button--search-select {
			background-color: map_get($colours, orange);
			color: white;
			padding-right: 48px;

			&::before {
				content: "";
				position: absolute;
				width: 2px;
				height: 100%;
				top: 0px;
				bottom: 0px;
				right: 35px;
				background-color: white;
			}

			&::after {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 22.523739 13.763274'%3E%3Cpath stroke-linejoin='round' d='m1.25 1.25 10 11.25 10-11.25' stroke='rgb(255, 255, 255)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2.5' fill='none'/%3E%3C/svg%3E");
			}
		}
	}

	input {
		outline: 0;
	}

	@media only screen and (max-width: 900px) {
		position: relative;
	}
}

.searchbox-mob {
	display: none;

	@media only screen and (max-width: 900px) {
		display: block;
	}
}

.fullsizebar {
	.searchbox__content {
		padding-left: 0px;
		padding-right: 0px;
	}
}

.job-filter {
	&--fixed {
		max-width: 220px;
		flex: 1 1 auto;
		width: 100%;
		justify-self: right;
		font-family: "FSAlbert", Arial, sans-serif;

		@media only screen and (max-width: 700px) {
			max-width: none;
			margin-top: 14px;
		}
	}

	&__button {
		position: relative;
		display: inline-block;
		background-color: #e8e8e8;
		font-size: 12px;
		margin-right: 6px;
		padding: 8px 18px 6px 30px;
		color: #8e898c;
		border-radius: 25px;
		cursor: pointer;

		&.active, &:hover {
			background-color: #fff;
		}

		@media only screen and (max-width: 900px) {
			margin-bottom: 10px;
			padding: 8px 10px 6px 30px;
			font-size: 11px;
			margin-right: 2px;
		}
	}

	&__key {
		display: inline-block;
		padding: 7px 8px 5px 0px;
		color: #8e898c;
		font-size: 14px;

		@media only screen and (max-width: 500px) {
			display: none;
		}
	}

	&__color {
		position: absolute;
		top: 5px;
		/*left: 6px;*/
		left: 8px;

		display: inline-block;

		/*
		height: 16px;
		width: 16px;
		border-radius: 30px;
		*/

		border-width: 16px 16px 0 0;
		border-style: solid;
		overflow: visible;
	}

	&--inactive {
		.job-filter__button:hover {
			background-color: #e8e8e8 !important;
			cursor: default !important;
		}
	}
}

.featured-jobs {
	&__title {
		flex: 1 1 auto;
	}

	@media only screen and (max-width: 900px) {
		padding-left: 3%;
		padding-right: 3%;

		& .searchbox {
			display: none;
		}
	}
}

#featured-home .job-cards {
	// opacity: 0; ???
}

@keyframes starclicked {
	0%	{
		opacity: 1;
	}
	20%	{
		opacity: 0.2;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeborder {
	0%	{
		border: 2px solid rgba(191, 191, 191, 0.7);
	}
	100% {
		border: 2px solid transparent;
	}
}

.job-cards {
	display: flex;

	.job-card {
		position: relative;
		font-size: 14px;

		&__inner {
			position: relative;
			display: block;
			margin: 0px;
			background-color: #fff;
			height: 100%;
			z-index: 1;
			min-height: 157px;
		}

		&__color {
			position: absolute;
			top: 0;
			left: 0;
			border-width: 40px 40px 0 0;
			border-style: solid;
			transition: top 0.16s ease-out, left 0.16s ease-out;
			transition-delay: 0.03s, 0.03s;
			z-index: 2;

			@media only screen and (max-width: 900px) {
				border-width: 30px 30px 0 0;
			}
		}

		&__actions {
			position: absolute;
			bottom: 14px;
			left: 0px;
			width: calc(89% + 17px);
			margin-left: 10px;
			margin-right: 10px;
			padding-left: 11%;
			padding-right: 18%;
			z-index: 2;
		}

		&__title {
			position: relative;
			z-index: 2;
		}

		&__star {
			position: absolute;
			top: 0px;
			right: 0px;
			height: 40px;
			width: 40px;
			cursor: pointer;
			top: -9px;
			right: 20px;
			background-repeat: no-repeat;
			background-position:center;
			background-size: 30px;
			z-index: 3;
			outline: 0;

			background-image: url("data:image/svg+xml,%3Csvg xmlns:rdf='http://www.w3.org/1999/02/22-rdf-syntax-ns%23' xmlns='http://www.w3.org/2000/svg' xml:space='preserve' height='41.25' viewBox='0 0 43.372875 41.25' width='43.373' version='1.1' xmlns:cc='http://creativecommons.org/ns%23' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:dc='http://purl.org/dc/elements/1.1/'%3E%3Cdefs%3E%3CclipPath id='clipPath118' clipPathUnits='userSpaceOnUse'%3E%3Cpath clip-rule='evenodd' d='m482.35 1390.5-10.722-7.463 3.7839 12.504-10.411 7.891 13.061 0.265 4.2881 12.34 4.2882-12.34 13.061-0.265-10.411-7.891 3.784-12.504-10.722 7.463z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg transform='matrix(1.25,0,0,-1.25,-581.25,1770)'%3E%3Cg clip-path='url(%23clipPath118)'%3E%3Cpath d='m460 1378h44.698v43h-44.698v-43z' fill='%23dadada'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

			&:hover, &.active {
				background-image: url("data:image/svg+xml,%3Csvg xmlns:rdf='http://www.w3.org/1999/02/22-rdf-syntax-ns%23' xmlns='http://www.w3.org/2000/svg' xml:space='preserve' height='41.25' viewBox='0 0 43.372875 41.25' width='43.373' version='1.1' xmlns:cc='http://creativecommons.org/ns%23' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:dc='http://purl.org/dc/elements/1.1/'%3E%3Cdefs%3E%3CclipPath id='clipPath118' clipPathUnits='userSpaceOnUse'%3E%3Cpath clip-rule='evenodd' d='m482.35 1390.5-10.722-7.463 3.7839 12.504-10.411 7.891 13.061 0.265 4.2881 12.34 4.2882-12.34 13.061-0.265-10.411-7.891 3.784-12.504-10.722 7.463z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg transform='matrix(1.25,0,0,-1.25,-581.25,1770)'%3E%3Cg clip-path='url(%23clipPath118)'%3E%3Cpath d='m460 1378h44.698v43h-44.698v-43z' fill='%23f17730'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
			}

			&:focus {
				animation: starclicked 0.4s ease-in-out;
			}
		}

		&__noclick {
			opacity: 0.4;
			cursor: default !important;

			&:hover {
				background-color: #f17730;
				border: 2px solid transparent;

				span {
					color: #FFF !important;
				}
			}
		}

		&__save:hover + div {
			background-image: url("data:image/svg+xml,%3Csvg xmlns:rdf='http://www.w3.org/1999/02/22-rdf-syntax-ns%23' xmlns='http://www.w3.org/2000/svg' xml:space='preserve' height='41.25' viewBox='0 0 43.372875 41.25' width='43.373' version='1.1' xmlns:cc='http://creativecommons.org/ns%23' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:dc='http://purl.org/dc/elements/1.1/'%3E%3Cdefs%3E%3CclipPath id='clipPath118' clipPathUnits='userSpaceOnUse'%3E%3Cpath clip-rule='evenodd' d='m482.35 1390.5-10.722-7.463 3.7839 12.504-10.411 7.891 13.061 0.265 4.2881 12.34 4.2882-12.34 13.061-0.265-10.411-7.891 3.784-12.504-10.722 7.463z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg transform='matrix(1.25,0,0,-1.25,-581.25,1770)'%3E%3Cg clip-path='url(%23clipPath118)'%3E%3Cpath d='m460 1378h44.698v43h-44.698v-43z' fill='%23f17730'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
		}

		&__screen {
			position: absolute;
			top: 0px;
			left: 0px;
			right: 0px;
			bottom: 0px;
			background-color: white;
			box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.05);
			transition: top 0.15s ease-out, right 0.15s ease-out, bottom 0.15s ease-out, left 0.15s ease-out, box-shadow 0.15s ease-out;
			z-index: 0;
			border: 2px solid transparent;
		}
	}

	&--index {
		width: calc(100% + 20px);
		flex-flow: row wrap;
		item-align: stretch;
		flex-wrap: wrap;
		margin-top: 25px;
		margin-bottom: 40px;
		margin-left: -10px;

		.job-card {
			flex: 1 1 25%;
			flex-wrap: wrap;
			min-width: 230px;
			max-width: 25%;
			margin-bottom: 20px;
			width: 25%;

			&__container {
				margin: 10px;

				&:hover {
					.job-card__screen {
						box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.1);
						top: -3px;
						left: -3px;
						right: -3px;
						bottom: -3px;
						animation: fadeborder 0.3s ease-out;
					}

					.job-card__color {
						top: -3px;
						left: -3px;
					}
				}
			}

			&__inner {
				min-height: 177px;
				padding: 10% 11%;
				padding-bottom: 49px;
			}

			&__actions {
				min-height: 45px;
			}

			@media only screen and (max-width: 900px) {
				flex: 1 1 50%;
				min-width: 200px;
				max-width: 50%;
				width: 50%;
				margin-bottom: 1px;
			}

			@media only screen and (max-width: 500px) {
				flex: 1 1 100%;
				max-width: 100%;
				width: 100%;
			}
		}

		@media only screen and (max-width: 700px) {
			margin-top: 0px;
			margin-bottom: 0px
		}
	}

	&--individual {
		.job-card {
			width: 100%;

			&__inner {
				display: flex;
				padding: 4.6% 6%;
				margin-right: 20px;

				@media only screen and (max-width:1200px) {
					flex-wrap: wrap;
				}

				@media only screen and (max-width:700px) {
					margin-right: 0px;
				}
			}

			&__container {
				height: 100%;
			}

			&__star {
				position: relative;
				top: 0px;
				right: initial;
				max-width: 40px;
			}

			&__save {
				width: calc(100% - 40px);
				padding-top: 14px;
				padding-left: 8px;
				padding-right: 5px;
				text-align: right;
			}

			&__save-outer {
				flex: 1 1 50%;
				max-height: 40px;
			}

			&__apply {
				flex: 1 1 48%;
			}

			&__respond {
				max-width: 267px;
				margin-left: 0px;
				margin-right: auto;

				&--top {
					display: none;
					margin-bottom: 20px;
					margin-top: 7px;

					@media only screen and (max-width: 900px) {
						display: flex;
					}
				}
			}
		}

		@media only screen and (max-width: 700px) {
			padding: 0 !important;
		}
	}

	&--row {
		flex-direction: column;

		.job-card {
			margin-bottom: 20px;

			&__inner {
				padding: 30px 30px 30px 30px;
				min-height: 0px;
			}

			&__respond {
				align-self: center;

				form,
				input {
					width: 100%;
				}
			}
		}
	}
}

.caption {
	position: absolute;
	font-family: 'CrimsonText', Arial, sans-serif;
	font-weight: 400;
	left: 0;
	width: 100%;

	&--white {
		color: #FFF;
	}

	&--black {
		color: #222;
	}

	&--size1 {
		font-size: 24pt;
		line-height: 24pt;
	}

	&--size2 {
		font-size: 36pt;
		line-height: 36pt;
	}

	&--size3 {
		font-size: 50pt;
		line-height: 50pt;
	}

	&--size4 {
		font-size: 60pt;
		line-height: 60pt;
	}

	&__posfix {
		width: 100%;
		max-width: 1300px;
		margin-left: auto;
		margin-right: auto;

		@media only screen and (max-width:1220px) and (min-width:900px) {
			margin: 0 20px 0 20px;
		}
	}

	@media only screen and (max-width: 1000px) {
		&--size1 {
			font-size: 3.2vw;
			line-height: 3.2vw;
		}

		&--size2 {
			font-size: 4.8vw;
			line-height: 4.8vw;
		}

		&--size3 {
			font-size: 6.7vw;
			line-height: 6.7vw;
		}

		&--size4 {
			font-size: 8vw;
			line-height: 8vw;
		}
	}
}

.back,
.viewall {
	position: relative;

	&::after {
		content: "";
		position: absolute;
		height: 2px;
		width: 0px;
		background-color: map_get($colours, pure-white);
		bottom: 11px;
		left: 50%;
		opacity: 0;
		transition: all 0.3s ease-out;
	}

	&:hover, &:active {
		&::after {
			opacity: 1;
			width: 136px;
			left: calc(50% - 68px);
		}
	}
}

.viewall {
	max-width: 200px;

	&:hover, &:active {
		&::after {
			width: 60px;
			left: calc(50% - 30px);
		}
	}
}

.content-indent {
	padding-left: 70px;
	padding-right: 70px;
	padding-bottom: 50px;

	@media only screen and (max-width: 900px) {
		padding-left: 14px;
		padding-right: 14px;
	}
}

.content-header {
	padding-top: 60px;
	padding-bottom: 60px;

	@media only screen and (max-width: 900px) {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	@media only screen and (max-width: 700px) {
		padding-bottom: 0px;

		&__item {
			display: flex;
			flex: 1 1 50%;
			flex-wrap: wrap;
			align-items: flex-start;
			align-content: flex-start;
		}
	}

	@media only screen and (max-width: 700px) {
		width: 100%;

		&__item {
			flex: 1 1 100%;
		}
	}
}

.content-title {
	font-size: 30pt;
	margin-top: 30px;

	@media only screen and (max-width: 500px) {
		font-size: 26pt;
	}
}

.content-nav {
	padding-top: 48px;
	padding-bottom: 48px;

	@media only screen and (max-width: 500px) {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}

.user-header {
	padding-top: 45px;
	padding-bottom: 45px;
}

.user-footer {
	padding-top: 40px;
	padding-bottom: 40px;
}

.registerform {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	// min-height: calc(100vh - 225px);  // The reason for this to be here seems to be linked with when the forms (i.e contact form) were showing a question at time…

	&__row {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		justify-content: center;
		overflow: hidden;
		// max-height: 0;
		transition: max-height 0s linear;

		label {
			display: block;
			font-size: 38px;
			margin-bottom: 9px;
		}

		&.bgc-light-orange {
			input:-webkit-autofill {
				-webkit-box-shadow: 0 0 0 30px map_get($colours, light-orange) inset;
			}
		}

		&.bgc-mid-orange {
			input:-webkit-autofill {
				-webkit-box-shadow: 0 0 0 30px map_get($colours, mid-orange) inset;
			}
		}

		&.bgc-orange {
			input:-webkit-autofill {
				-webkit-box-shadow: 0 0 0 30px map_get($colours, orange) inset;
			}
		}

		input:-webkit-autofill {
			-webkit-text-fill-color: white !important;
		}
	}

	&__pos {
		padding-top: 60px;
		padding-bottom: 60px;

		@media only screen and (max-width: 500px) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&__info,
	&__sub {
		display: block;
		font-size: 14px;
		color: #ffdcc5;
	}

	&__info {
		margin-bottom: 9px;
	}

	&__sub {
		margin-top: 20px;
		margin-bottom: 0px;

	}

	&__text {
		display: block;
		margin: 0px auto 4px auto;
		padding: 15px 0px 15px 0px;
		font-size: 20px;
		color: map_get($colours, pure-white);
		background-color: transparent;
		border: none;
		border-bottom: 2px solid map_get($colours, pure-white);
		min-width: 350px;
		outline: 0;

		&--inline {
			margin: 10px auto 10px 20px;
			padding: 0 15px;
		}

		&.textarea {
			background-color: map_get($colours, mid-orange);
			margin-top: 22px;
			padding-left: 20px;
			padding-right: 20px;
		}

		@media only screen and (max-width: 500px) {
			min-width: calc(100% - 30px);
			margin-left: 15px;
		}
	}

	&__actions {
		flex-direction: row;

		@media only screen and (max-width: 500px) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&__register {
		position: relative;
		color: map_get($colours, content-grey);
		background-color: transparent;
		padding: 26px 74px 24px 28px;
		border: 1px solid transparent;
		border-radius: 40px;
		font-size: 20px;
		line-height: 1;
		font-family: "FSAlbert", Arial, sans-serif;
		cursor: pointer;
		z-index: 3;
		outline: 0 !important;
        user-select: none;

		@media only screen and (max-width: 500px) {
			padding-top: 29px;
			font-size: 16px;
		}
	}

	&__register-pos {
		position: relative;
		display: inline-block;
		margin-top: 10px;
		margin-bottom: 60px;

		background-color: map_get($colours, pure-white);
		border-radius: 40px;
		max-height: 72px;

		&::after {
			content: "";
			position: absolute;
			top: 11px;
			right: 10px;
			height: 53px;
			width: 53px;
			background-color: map_get($colours, content-grey);
			border-radius: 40px;
			cursor: pointer;
			z-index: 1;

			background-repeat: no-repeat;
			background-position: 21px center;
			background-size: 14px;
		}

		@keyframes rotateLoader {
			0%	{
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}

		&::before {
			content: "";
			position: absolute;
			display: none;
			top: 21px;
			right: 20px;
			height: 33px;
			width: 33px;
			cursor: pointer;
			z-index: 3;
			animation: rotateLoader 1.4s infinite;
			animation-timing-function: linear;

			background-repeat: no-repeat;
			background-position: center;
			background-size: 100%;
			background-image: url("../../img/loading.png");
		}

		.proceedRight,
		.proceedLeft {
			position: absolute;
			top: 26px;
			right: 22px;
			width: 22px;
			height: 22px;
			z-index: 2;
			stroke: #fff;
			cursor: pointer;
			transition: right 0.07s ease-out;
		}

		.proceedLeft {
			transform: rotate(180deg);
			right: 26px;
		}

		.tickBottom,
		.tickBack {
			transition: transform 0.07s ease-out;
		}

		@keyframes fadeTick {
			0%	{
				opacity: 1;
			}
			30%	{
				opacity: 0.8;
			}
			100% {
				opacity: 1;
			}
		}

		@keyframes shakeChevron {
			0%	{
				transform: translate(0px, 0px);
			}
			50%	{
				transform: translate(0px, 1px);
			}
			100% {
				transform: translate(0px, 0px);
			}
		}

		&:hover {
			.proceedRight {
				right: 21px;
			}

			.tickBottom {
				transform: rotate(70deg) translate(-6px, -10px);
			}

			.tickBack {
				animation: shakeChevron 0.7s forwards;
			}

			.registerform__register {
				/*box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.07);*/
			}
		}

		&.activated {
			&::before {
				display: block;
			}

			.proceedRight {
				display: none;
			}
		}
	}

	&--min {
		min-height: initial;
	}

	&__back-pos {
		background-color: transparent;
		margin-left: -10px;
		margin-right: 20px;
	}

	&__back {
		padding: 0px;
		width: 74px;
		height: 74px;
	}

	&__other {
		display: none;
	}

	&__show {
		display: block;
	}

	&__error {
		position: relative;
		display: inline-block !important;

		&::after {
			content: "";
			position: absolute;
			top: 1px;
			height: 29px;
			width: 36px;
			cursor: default;
			animation: fadeTick 2.4s infinite;
			animation-timing-function: linear;

			background-repeat: no-repeat;
			background-position: center;
			background-size: 100%;
			background-image: url("../../img/error.png");
		}

		&::after {
			right: -60px;
		}
	}

	&--small {
		min-height: 350px;
	}

	input {
		outline: 0px;
	}

	::-webkit-input-placeholder {
		color: white;
	}

	::-moz-placeholder {
		color: white;
	}

	::-ms-placeholder {
		color: white;
	}

	::placeholder {
		color: white;
	}
}

.display-all .registerform__row {
	display: flex;
}

.showRow {
	display: flex;
	max-height: 1000px;
	transition: max-height 1.5s ease-out;

	&#stage_1_1,
	&#stage_2_1,
	&#stage_3_1,
	&#stage_4_1,
	&#stage_5_1,
	&.shownRow {
		transition: max-height 0s linear;
	}
}

.longrow {
	position: relative;
	margin-left: auto;
	margin-right: auto;

	&__remove {
		position: absolute;
		display: block;
		top: calc(50% - 25px);
		right: -50px;
		color: #ffcaa7;
		font-size: 50px;
		font-weight: 300;
		transition: color 0.07s ease-out;
		width: 50px;
		cursor: pointer;

		span {
			display: block;
		}

		::selection {
			background: transparent;
		}

		&:hover {
			color: #fff;
		}
	}

	&.blank {
		min-height: 60px;
		border: 2px dashed #ffcaa7;
		cursor: pointer;
		max-width: 352px;

		&::after {
			content: "+";
			position: absolute;
			display: block;
			top: calc(50% - 25px);
			left: calc(50% - 15px);
			color: #ffcaa7;
			font-size: 50px;
			font-weight: 300;
			transition: color 0.07s ease-out;
		}

		&:hover {
			&::after {
				color: #fff;
			}
		}

	}

	&--cv {
		justify-content: right;

		.longrow__row {
			flex: 1 1 auto;
			text-align: left;
		}

		.longrow__remove {
			top: -4px;
			right: -20px;
		}
	}

	@media only screen and (min-width: 1050px) {
		display: flex;
		max-width: 1050px !important;

		&__row {
			min-width: 130px;
			margin-left: 7px;
			margin-right: 7px;
		}

		& input:nth-child(4) {
			min-width: 150px;
		}

		& input:nth-child(6) {
			min-width: 230px;
		}

		&--cv {
			max-width: 500px !important;
		}
	}
}

.filters {
	display: flex;

	.button {
		margin-right: 6px;
		margin-left: 6px;

		@media only screen and (max-width: 500px) {
			margin: 0px 0px 8px;
			width: 100%;
		}
	}

	div:first-of-type {
		margin-left: 0px;
	}

	div:last-of-type {
		margin-right: 0px;
	}

	div:nth-child(1) {
		z-index: 4;
	}

	div:nth-child(2) {
		z-index: 3;
	}

	div:nth-child(3) {
		z-index: 2;
	}

	@media only screen and (max-width: 700px) {
		flex-wrap: wrap;
		width: 100%;
	}
}

@keyframes fadeOrange {
	0%	{
		border-bottom: 2px solid #f36518;
	}
	1%	{
		border-bottom: 2px solid #e9a034;
	}
	100% {
		border-bottom: 2px solid #f36518;
	}
}

.info {
	&__row {
		margin-top: 35px;
		max-width: 620px;
	}

	&__main {
		font-size: 23pt;
	}

	@media only screen and (max-width: 700px) {
		padding-left: 5%;
		padding-right: 5%;

		&__main {
			margin-top: 15px;
			margin-bottom: 10px;
		}

		&__services {
			display: none;
		}
	}
}

@keyframes fadeTodo {
	0%	{
		opacity: 1;
	}
	15%	{
		opacity: 0.4;
	}
	50%	{
		opacity: 1;
	}
	65% {
		opacity: 0.4;
	}
	100% {
		opacity: 1;
	}
}

.actions {
	background-color: #393f48;
	color: #FFF;
	padding: 40px 45px 40px 45px;

	&__account {
		vertical-align: top;

		img {
			margin-right: 18px;
			position: relative;
			top: -2px;
			width: 36px;
			height: 36px;
		}
	}

	&__row {
		display: block;
		border-bottom: 2px solid #f36518;
		padding-bottom: 3px;
		padding-top: 8px;
		cursor: pointer;
		transition: padding-left 0.2s ease-out, color 0.1s ease-out;
		flex: 1 1 100%;

		&:hover {
			padding-left: 6px;
			color: #7e7e7e;
			animation: fadeOrange 0.4s ease-in-out;
		}
	}

	&__social-button {
		display: inline-block;
		margin-left: 12px;
		margin-right: 12px;
		max-width: 80px;
		cursor: pointer;
		transition: opacity 0.1s ease-out;

		img {
			width: 100%;
		}

		&:hover {
			opacity: 0.7;
		}

		@media only screen and (max-width: 500px){
			max-width: 40px;
			padding-bottom: 12px;
		}
	}

	&__sign-in,
	&__link {
		margin-top: 12px;
	}

	&__sign-in {
		display: block;
		text-align: center;
	}

	&__link {
		position: relative;
		display: inline-block;
		text-align: left;

		.info {
			position: absolute;
			top: 0px;
			right: -21px;
			height: 15px;
			width: 15px;
			cursor: default;
			animation: fadeTodo 1.8s forwards;
			animation-timing-function: linear;
			animation-delay: 1s;
			transform: rotate(180deg);

			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			background-image: url("../../img/icon-proceed.svg");
		}
	}

	&__settings {
		flex: 1 1 48%;
		padding-right: 31px;

		.actions__link-row {
			&:first-of-type {
				a {
					margin-top: 0px;
				}
			}
		}
	}

	&__todo {
		flex: 1 1 52%;
		padding-left: 33px;
		border-left: 2px solid #5c6266;
	}

	&__account-panel {
		display: flex;
		width: 100%;
	}

	&--orange {
		background-color: map_get($colours, orange);
	}

	&--pad {
		margin-left: 12px;
		margin-right: 12px;

		@media only screen and (max-width: 700px) {
			margin-left: 0px;
			margin-right: 0px;
			margin-bottom: 16px;
		}
	}

	@media only screen and (max-width: 1041px) and (min-width: 700px) {
		&__account-panel {
			flex-direction: column;
		}

		&__todo {
			border-left: 0;
			border-top: 2px solid #5c6266;
			margin-top: 20px;
			padding: 0;
			padding-top: 20px;
		}

		&__account {
			font-size: 26px;

			img {
				display: none;
			}
		}
	}

	@media only screen and (max-width: 530px) {
		&__account-panel {
			flex-direction: column;
		}

		&__todo {
			border-left: 0;
			border-top: 2px solid #5c6266;
			margin-top: 20px;
			padding: 0;
			padding-top: 20px;
		}

		&__account {
			font-size: 26px;
		}

		& > form,
		& > a {
			width: 100%;
			flex: 1 1 100%;
		}
	}

	@media only screen and (max-width: 430px) {
		&__account {
			font-size: 8vw;
		}
	}

	ul {
		margin-top: 30px;
		margin-bottom: 30px;
		font-weight: 300;

		li {
			margin-bottom: 15px;
		}
	}

	a:first-of-type {
		padding-top: 0px;
	}

	.submit-group {
		position: relative;
		flex: 0;

		&::after {
			content: "";
			position: absolute;
			height: 2px;
			width: 0px;
			background-color: map_get($colours, pure-white);
			bottom: 11px;
			left: 50%;
			opacity: 0;
			transition: all 0.3s ease-out;
		}

		&:hover, &:active {
			&::after {
				opacity: 1;
				width: 108px;
				left: calc(50% - 54px);
			}
		}
	}

	.ul {
		&--sml {
			&:hover, &:active {
				&::after {
					width: 50px;
					left: calc(50% - 25px);
				}
			}
		}

		&--med {
			&:hover, &:active {
				&::after {
					width: 126px;
					left: calc(50% - 63px);
				}
			}
		}

		&--lg {
			&:hover, &:active {
				&::after {
					width: 138px;
					left: calc(50% - 69px);
				}
			}
		}
	}

	@media only screen and (max-width: 700px) {
		> div,
		> a {
			margin-left: auto;
			margin-right: auto;
		}
	}

	@media only screen and (max-width: 500px) {
		padding: 20px 20px 20px 20px;
	}

	@media only screen and (min-width: 700px) {
		min-height: 370px;
	}
}

.social {
	min-height: 450px;

	&__cell {
		display: flex;
	}

	&__content {
		align-self: center;
		z-index: 1;
	}
}

.stripes {
	position: relative;
	overflow: hidden;
	z-index: 0;

	.submit-group {
		position: relative;
		width: auto;
		margin: 0px auto 0px auto;
		display: inline-block;
		flex: 0;

		&::after {
			content: "";
			position: absolute;
			height: 2px;
			width: 0px;
			background-color: map_get($colours, pure-white);
			bottom: 11px;
			left: 50%;
			opacity: 0;
			transition: all 0.3s ease-out;
		}

		&:hover, &:active {
			&::after {
				opacity: 1;
				width: 134px;
				left: calc(50% - 67px);
			}
		}
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		border-width: 150px 150px 0 0;
		border-style: solid;
		border-color: #8ebad3 transparent;
	}

	&:after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		border-width: 100vh 100vh 0 0;
		border-style: solid;
		border-color: transparent #fab041;
	}
}

.hidden {
	display: none;
}

.un {
	user-select: none;
}

.cvTitle {
	width: 350px;
	width: calc(100% - 30px);
}

.fileupload {
	position: relative;
	overflow: hidden;
	display: inline-block;
	margin: 10px;
	padding: 11px 15px 9px 15px;
	font-size: 20px;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	user-select: none;
	background-image: none;
	border: 2px solid rgba(255, 255, 255, 0.3);
	color: #fff;
	background-color: transparent;
	transition: border 0.1s ease-out;
}

.fileupload:hover {
	border: 2px solid rgba(255, 255, 255, 1);
}

.filename {
	display: none;
}

.fileupload span {
	cursor: pointer;
}

.fileupload input {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0;
	padding: 0;
	font-size: 20px;
	cursor: pointer;
	opacity: 0;
	filter: alpha(opacity=0);
	outline: none;
}

.filesubmit {
	display: none;
	margin-top: 10px;
}

.filedownload {
	text-align: center;
}

.filedownload .fileupload {
	width: auto;
	margin-left: 7px;
	margin-right: 7px;
}

.button2div {
	background-color: transparent;
	outline: none;
	border: 0;
}

.more-info {
	&__link {
		display: inline-block;
		font-size: 20px;
		margin-right: 20px;
		color: map_get($colours, pale-blue);
		text-decoration: underline;
		margin-top: 8px;
	}
}

.cvlinkfront {
	margin-top: 18px;
	display: block;
}















.job-display {
    height: 100vh;
    position: relative;

	/*&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		border-width: 100vh 0 0 100vh;
		border-style: solid;
		border-color: transparent #f57529;
		z-index: -1;
	}*/
}


.display {
	background-color: #f46517;
	height: 100vh;
	overflow-x: hidden;

  	// Add orange triangle in bottom left of screen
  	background-image: url(../../img/orange-triangle.png);
  	background-repeat: no-repeat;
  	background-size: 100% 75%;
  	background-position: bottom  left;
}

/*grid-template-rows: repeat(5, 1fr);
grid-gap: 2.9vh 6.2vw;
*/

.display-cards {

	position: relative;
	height: 92vh;
	max-width: 97.8vw;
	display: flex;
	flex-wrap: wrap;

	padding: 4vh;
	max-width: 97.7vw;
	align-content: flex-start;

	&::after {
		content: "work@topjobs.je  |  727323  |  www.topjobs.je";
		position: absolute;
		height: 8vh;
		width: 100vw;
		bottom: -8vh;
		left: 0;
		text-align: center;
		font-size: 3.2vw;
		color: white;
		white-space: pre;
		text-transform: uppercase;
	}

	.display-card {
		display: flex;
		position: relative;
		font-size: 14px;
		height: 16.8vh;
		flex: 1 1 50%;
		min-width: 50%;
		max-width: 50%;

		&__container {
			display: flex;
			flex: 1 1 auto;
			padding: 1.5vw 2.2vw;
			max-width: 100%;
		}

		&__inner {
			position: relative;
			display: flex;
			flex: 1 1 auto;
			flex-direction: column;

			margin: 0px;
			background-color: #fff;

			padding: 2vw 2.1vw 1.1vw 2.1vw;
			border: 3px solid white;

			max-width: 100%;
		}

		&__color {
			position: absolute;
			top: 0;
			right: 0;
			border-width: 7.1vw 0 0 7.1vw;
			border-style: solid;
		}

		&__info {
			display: flex;
			width: 100%;
			margin-top: 2.1vw;
			flex: 1 1 auto;
		}

		&__title {
			font-size: 2.1vw;
			line-height: 2.1vw;
			text-transform: uppercase;
			color: #f46517;
			padding-right: 3.1vw;
		}

		&__type {
			font-size: 1.7vw;
			line-height: 2.5vw;
			text-transform: uppercase;
		}

		&__more-info {
			font-size: 1.3vw;
			line-height: 1.5vw;
			color: #343842;
			align-self: flex-end;
			white-space: pre;
			flex: 1 1 56%;

			strong {
				font-size: 1.5vw;
			}
		}

		&__salary {
			display: flex;
			font-size: 3.5vw;
			line-height: 3.8vw;
			font-weight: bold;
			color: #6e6b6c;
			flex: 1 1 74%;
			align-self: flex-end;
			padding-left: 1.5vw;
		}

		&__salary-inner {
			align-self: flex-end;
			text-align: right;
			width: 100%;
			margin-bottom: -0.8vw;
		}

		&__text {
			font-size: 1.3vw;
			line-height: 1.5vw;
			//color: #696a6c;
		  	color: #000;
			margin-top: 1vw;
			overflow: hidden;
			flex: 1 1 auto;
		}
	}
}


.display-front {
	position: relative;
	height: 100vh;
	max-width: 97.7vw;
	padding: 12vw;
	z-index: 2;

	.display-logo-text {
	  	position: relative;
	  	//top: 0;
	  	//left: 50px;
	  	display: flex;
	  	align-items: center;
		font-size: 16vw;
		line-height: 11.5vw;
		font-weight: bold;
		height: 15vh;
		z-index: 2;

		img {
			width: 8.4vw !important;
		}

		&__text {
			font-size: 4.6vw;
			line-height: 9.8vw;
			font-weight: bold;
			vertical-align: top;
			margin-left: 1.5vw;
		}
	}

	.display-qr {
		font-size: 16vw;
		line-height: 11.5vw;
		font-weight: bold;
		margin-top: 3vh;
		z-index: 2;

		img {
			vertical-align: top;
			width: 12vw;
		}

		&__text {
			font-size: 2.9vw;
			line-height: 2.8vw;
			vertical-align: bottom;
			margin-left: 3vw;
			align-self: flex-end;
			font-weight: normal;
			color: white;
			margin-bottom: -0.6vw;
		}

		&__pos {
			display: flex;
		}
	}

	.display-footer {
		position: absolute;
		bottom: 3vh;
	}

	.display-large-text {
	  	flex: 1 1 100%;
		font-size: 19.5vw;
		line-height: 16.5vw;
		color: white;
		white-space: nowrap;
		font-weight: bold;
		z-index: 2;
	}
}



