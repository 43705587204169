//   ###  #  #   ##   #  #  ####   #
//  #     #  #  #  #  ####  #      #
//   ##   ####  #  #  ####  ###    #
//     #  #  #  ####  #  #  #
//  ###   #  #  #  #  #  #  ####   #
//


// This file contains all the horrible, shameful acts of CSS that were required for the
// website. Hopefully it’s empty. The rules live here to keep the codebase clean and
// make it easy to identify items that could be easier to update in the future.

.title-pad {
	@media only screen and (max-width: 700px) {
		margin-left: 30px;
		padding-bottom: 30px;
	}
}

.title-pad-sml {
	@media only screen and (max-width: 700px) {
		margin-left: 20px;
		padding-bottom: 11px;
	}
}

.map-margin {
	@media only screen and (max-width: 700px) {
		margin-top: 30px;
		margin-bottom: 30px;
	}
}

@media only screen and (max-width: 900px) {
	.footer__contact div {
		&:first-of-type {
			display: none;
		}

		&:last-of-type {
			width: 100%;
			text-align: center;
			border-bottom: 1px solid #3b3b3b;
			margin-bottom: 5px;
		}
	}
}