

.img-fade {
	opacity: .3;
}

.u-hidden {
	display: none;
}

.custom-bullet {
	& ul, & ol{
		list-style: none;
		display: inline-block;
		padding: 0;
		padding-left: 1.5em;
		margin-top: 0px;
		max-width: 100%;
	}

	& ul > li, & ol > li {
		clear: left;
		margin-bottom: 10px;
	}

	& ul > li:last-of-type, & ol > li:last-of-type {
		margin-bottom: 0px;
	}

	& ol {
		counter-reset: liNum;
	}

	& ol > li {
		counter-increment: liNum 1;
		display: flex;
		flex-wrap: wrap;
	}

	& ul > li:before, & ol > li:before {
		content:"";
		height:1em;
		width:1em;
		display:block;
		float:left;
		margin-left:-1.5em;
		background-size: 100%;
	}

	& ol > li:before {
		position: absolute;
		content: counter(liNum);
		text-align: center;
	}

	&--tick ul > li {
		color: map-get($colours, white);
	}

	&--tick ul > li:before {
		background: url(../../img/icon-tick.svg) no-repeat;
		background-size: 85%;
		background-position: center;
	}

	&--tri ul > li:before {
		position: relative;
		top: -1px;
		background: url(../../img/icon-tri.svg) no-repeat;
		background-size: auto 100%;
		background-position: center;
	}
}

.header-max {
	max-height: 130px;
}

.min-height-page {
	min-height: calc(100vh - 443px);
}

@keyframes flashOnLoad {
	0%	{
		opacity: 1;
	}
	30%	{
		opacity: 0.3;
	}
	100% {
		opacity: 1;
	}
}

@keyframes foldUp {
	0%	{
		max-height: 70px;
		opacity: 1;
	}
	100% {
		max-height: 0px;
		opacity: 0.5;
	}
}

.user-alert {
	flex: 0 0 auto !important;
	max-height: initial !important;
	color: map-get($colours, white) !important;
	padding: 30px;
	background-color: #393f48;
	box-shadow: inset 0px 0px 16px 0px rgba(0, 0, 0, 0.08);

	& .alert-flash {
		animation: flashOnLoad 1.7s forwards;
	}

	&--list {
		overflow: hidden;
		padding: 0px;

		animation: foldUp 0.6s forwards;
		animation-timing-function: linear;
		animation-delay: 4.2s;

		& .alert-flash {
			display: block;
			padding: 20px;
		}
	}
}

.fileicon {
	width: 32px;
	height: 32px;
	border: 0px;
	display: inline-block;
	margin-right: 5px;
	background-size: auto 32px !important;
	vertical-align: top;

	&.pdf {
		background: url("../../img/pdf.svg") no-repeat;
	}

	&.doc {
		background: url("../../img/doc.svg") no-repeat;
	}

	&.text {
		background: url("../../img/txt.svg") no-repeat;
	}

	&.unknown {
		background: url("../../img/file.svg") no-repeat;
	}
}
