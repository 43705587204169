.t-slider {
	&__slide-pos {
		position: absolute;
		display: flex;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		right: 0;
	}

	&__slide {
		opacity: 0;

		@media only screen and (max-width: 900px) {
			padding-left: 12px;
			padding-right: 12px;
		}
	}

	& > div:first-of-type > div {
		opacity: 1;
	}
}
