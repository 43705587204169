//   ###  #### ##### #####  ###   #  #   ###   ###
//  #     #      #     #     #    ## #  #     #
//   ##   ###    #     #     #    # ##  # ##   ##
//     #  #      #     #     #    #  #  #  #     #
//  ###   ####   #     #    ###   #  #   ###  ###
//

$debug: false;

@font-face {
	font-family: 'FSAlbert';
	font-weight: 700;
	font-style: normal;
	src: url('fonts/3158DB_0_0.eot');
	src: url('fonts/3158DB_0_0.eot?#iefix') format('embedded-opentype'), url('fonts/3158DB_0_0.woff2') format('woff2'), url('fonts/3158DB_0_0.woff') format('woff'), url('fonts/3158DB_0_0.ttf') format('truetype');
}

@font-face {
	font-family: 'FSAlbert';
	font-weight: 400;
	font-style: normal;
	src: url('fonts/3158DB_2_0.eot');
	src: url('fonts/3158DB_2_0.eot?#iefix') format('embedded-opentype'), url('fonts/3158DB_2_0.woff2') format('woff2'), url('fonts/3158DB_2_0.woff') format('woff'), url('fonts/3158DB_2_0.ttf') format('truetype');
}

@font-face {
	font-family: 'FSAlbert';
	font-weight: 300;
	font-style: normal;
	src: url('fonts/3158DB_1_0.eot');
	src: url('fonts/3158DB_1_0.eot?#iefix') format('embedded-opentype'), url('fonts/3158DB_1_0.woff2') format('woff2'), url('fonts/3158DB_1_0.woff') format('woff'), url('fonts/3158DB_1_0.ttf') format('truetype');
}

@font-face {
	font-family: 'CrimsonText';
	src: url('fonts/CrimsonText.eot');
	src: url('fonts/CrimsonText.eot?#iefix') format('embedded-opentype'), url('fonts/CrimsonText.woff2') format('woff2'), url('fonts/CrimsonText.woff') format('woff'), url('fonts/CrimsonText.ttf') format('truetype');
}

@font-face {
	font-family: 'CrimsonText-Italic';
	src: url('fonts/CrimsonText-Italic.eot');
	src: url('fonts/CrimsonText-Italic.eot?#iefix') format('embedded-opentype'), url('fonts/CrimsonText-Italic.woff2') format('woff2'), url('fonts/CrimsonText-Italic.woff') format('woff'), url('fonts/CrimsonText-Italic.ttf') format('truetype');
}

$colours: (
	body-text: #393f48,
	pure-white: #FFF,
	pure-black: #000,
	light-orange: #f38743,
	mid-orange: #f2803a,
	orange: #f17730,
	brown: #212121,
	footer-grey: #282a2d,
	dark-grey: #393f48,
	mid-grey: #dadada,
	ref-grey: #8e898c,
	bg-grey: #f3f3f3,
	light-grey: #a1a2a4,
	header-grey: #BFBDBD,
	content-grey: #49505a,
	pale-blue: #9ec6dc,
	slate: #414a56,
);

/*
Font: Gibson, hosted with Typekit in Ric’s account.
Regular: 400
Semi-bold: 600
*/
$font-family-body: 'FSAlbert', Arial, sans-serif;
$font-light-weight: 300;
$font-body-weight: 400;
$font-bold-weight: 600;

// Standard line-height for content
$line-height: 1.3;

// All the margin and padding utility classes to be created
$spaces: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200;

// Widths of the spans
$widths: (
	medium: 1200px,
	wide: 1400px,
	narrow: 500px,
);

::selection {
	background-color: #666;

	color: #fff;
}
