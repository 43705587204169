/*
Specify all the typographic styles that are going to be reused.
*/

html {
	color: map_get($colours, body-text);
	font-family: $font-family-body;
	font-size: 13pt;
	font-weight: $font-body-weight;
	letter-spacing: .05ch;
}


// #####         ### ##### #   #  #     ####   ###
//   #          #      #    # #   #     #     #
//   #           ##    #     #    #     ###    ##
//   #             #   #     #    #     #        #
//   #          ###    #     #    ####  ####  ###
//

.t {
	&-1 {
		margin-bottom: .2em;

		font-size: 23pt;
		font-weight: $font-bold-weight;
		line-height: 1.2;

	}

	&-2 {
		margin-bottom: .5em;

		font-weight: $font-bold-weight;
		font-size: 18pt;
		line-height: 1.1;
		text-align: left;

		@media only screen and (max-width: 500px) {
			font-size: 22pt;
		}

		@media only screen and (max-width: 340px) {
			font-size: 18pt;
		}
	}

	&-3 {
		font-size: 11pt;
		font-weight: $font-light-weight;
		line-height: 1;
	}

	&-4 {
		font-size: 10pt;
		line-height: 1.6;
		font-weight: $font-body-weight;
	}

	&-5 {
		font-size: 16pt;
		line-height: 1.2;
		font-weight: $font-body-weight;
	}

	&-6 {
		font-size: 12pt;
	}

	&-7 {
		font-size: 24pt;
		line-height: 1.25;

		@media only screen and (max-width: 600px) {
			line-height: 1.18;
			font-size: 20pt;
		}
	}

	&-8 {
		font-size: 18pt;
		line-height: 1.2;
	}

	&-9 {
		font-size: 25pt;
		line-height: 1.2;

		@media only screen and (max-width: 600px) {
			font-size: 22pt;
		}
	}

	&-10 {
		font-size: 12px;
		line-height: 1.2;
	}

	&-11 {
		font-size: 13px;
		line-height: 1.2;
	}

	&-12 {
		font-size: 16px;
		line-height: 1;
	}

	&-small {
		margin-bottom: .3em;

		font-size: 10pt;
		line-height: 1.2;
		text-transform: uppercase;
	}

	&-crimson {
		font-family: CrimsonText;
	}

	&--light {
		font-weight: $font-light-weight;
	}

	&--regular {
		font-weight: $font-body-weight;
	}

	&--bold {
		font-weight: $font-bold-weight;
	}

	&--left {
		text-align: left;
	}

	&--center {
		text-align: center;
	}

	&--underline {
		text-decoration: underline;
	}

	&--mo-underline {
		&:hover {
			text-decoration: underline;
		}
	}

	&--upper {
		text-transform: uppercase;
	}

	/*
	Used to keep words together. This is a sentence, keep <span class="t--widows">these words together</span>
	*/
	&--widows {
		white-space: nowrap;
	}
}

// #####   ##    ###         ###  #### ##### #####  ###   #  #   ###   ###
//   #    #  #  #           #     #      #     #     #    ## #  #     #
//   #    #  #  # ##         ##   ###    #     #     #    # ##  # ##   ##
//   #    ####  #  #           #  #      #     #     #    #  #  #  #     #
//   #    #  #   ###        ###   ####   #     #    ###   #  #   ###  ###
//

/*
Default styles to add to various tags
*/

blockquote {
	> p {
	}

	cite {
	}
}


b, strong {
	font-weight: $font-bold-weight;
}