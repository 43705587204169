.contact {
	width: 100%;
	color: map-get($colours, mid-grey);

	@media only screen and (max-width: 900px) {
		& .grid__cell {
			display: block;
		}
	}

	&__address {
		@media only screen and (min-width: 900px) {
			width: 30%;
		}
		@media only screen and (min-width: 680px) {
			padding-right: 20px;
		}
		@media only screen and (max-width: 680px) {
			width: 100%;
		}
	}

	&__map {
		flex: 1 1 0px;

		@media only screen and (max-width: 700px) {

			> div:first-of-type {
				margin-bottom: 8px;
			}
		}
	}

	&__instructions {
		padding-left: 30px;

		br {
			margin-bottom: 5px;
		}

		p {
			margin-bottom: 10px;
		}

		span {
			color: #b1b0b0;
		}
	}
}
