//   ###  #  #   ###         ### ##### #   #  #     ####   ###
//  #     ####  #           #      #    # #   #     #     #
//  #     ####   ##          ##    #     #    #     ###    ##
//  #     #  #     #           #   #     #    #     #        #
//   ###  #  #  ###         ###    #     #    ####  ####  ###
//

/*
This is where we break a few rules. All HTML coming from the CMS should be wrapped in the .cms-content class. This is
used to sandbox the styles from the rest of the website. We target children of the class for styling.
*/

.cms-content {
	line-height: $line-height;

	p, ol, ul {
		margin-bottom: .9em;
	}

	p:last-of-type {
		margin-bottom: 0;
	}

	a:link, a:visited {
		color: #386e8e;
		font-weight: 400;
	}

	a:hover, a:active {
		color: #769db4;
	}

	iframe {
		width: 100%;
	}

	OL {
		counter-reset: item;
	}

	ol LI {
		display: block;
		position: relative;
		margin-top: 11px;
		padding-left: 30px;
	}

	ol LI LI {
		padding-left: 40px;
	}

	ol LI LI LI {
		padding-left: 50px;
	}

	ol.rn LI,
	ol.al LI {
		padding-left: 30px;
	}

	ol LI:before {
		content: counters(item, ".") " ";
		counter-increment: item;
		position: absolute;
		left: 0px;
		top: 0px;
	}

	ol.rn {
		counter-reset: rn;
	}

	ol.rn li:before {
		content: counter(rn, lower-roman);
		counter-increment: rn;
	}

	ol.al {
		counter-reset: al;
	}

	ol.al li:before {
		content: counter(al, lower-alpha);
		counter-increment: al;
	}

	ol LI .pr-header {
		display: block;
		margin-top: 9px;
		font-style: italic;
	}

	ol ol,
	ul ul {
		max-width: 100%;
	}

	table {
		margin-top: 20px;
		margin-bottom: 20px;

		td {
			vertical-align: top;
		}
	}

	ul li {
		margin-left: 32px;
		margin-bottom: 14px;
	}
}